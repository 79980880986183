<template>
  <div id="combo-create-page">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-else class="p-4">
      <form action="">
        <div class="vx-row mb-6">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.code')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="{ required: combo.isActive || combo.canPublished }" name="code" class="w-full" v-model="combo.code" placeholder="101012345" />
            <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.bar_code')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="'required|numeric'" class="w-full" name="barcode" v-model="combo.bar_code" placeholder="4657867897" />
            <span class="text-danger text-sm" v-show="errors.has('barcode')">{{ errors.first('barcode') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.title')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <div class="vx-row">
                <div class="vx-col my-2 md:my-0 md:w-1/3 w-full">
                  <vs-input v-validate="'required'" class="w-full" name="name_tm" placeholder="Turkmen" v-model="combo.name_tm" />
                  <span class="text-danger text-sm" v-show="errors.has('name_tm')">{{ errors.first('name_tm') }}</span>
                </div>
                <div class="vx-col my-2 md:my-0 md:w-1/3 w-full">
                  <vs-input v-validate="'required'" class="w-full" name="name_ru" placeholder="Russian" v-model="combo.name_ru" />
                  <span class="text-danger text-sm" v-show="errors.has('name_ru')">{{ errors.first('name_ru') }}</span>
                </div>
                <div class="vx-col my-2 md:my-0 md:w-1/3 w-full">
                  <vs-input v-validate="'required'" class="w-full" name="name_en" placeholder="English" v-model="combo.name_en" />
                  <span class="text-danger text-sm" v-show="errors.has('name_en')">{{ errors.first('name_en') }}</span>
                </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.description')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <div class="vx-row">
              <div class="vx-col my-2 md:my-0 md:w-1/3 w-full">
                <vs-textarea v-validate="'required'" class="w-full mb-0" name="descr_tm" rows="4" placeholder="Turkmen" v-model="combo.description_tm"></vs-textarea>
                <span class="text-danger text-sm" v-show="errors.has('descr_tm')">{{ errors.first('descr_tm') }}</span>
              </div>
              <div class="vx-col my-2 md:my-0 md:w-1/3 w-full">
                <vs-textarea v-validate="'required'" class="w-full mb-0" name="descr_ru" rows="4" placeholder="Russian" v-model="combo.description_ru"></vs-textarea>
                <span class="text-danger text-sm" v-show="errors.has('descr_ru')">{{ errors.first('descr_ru') }}</span>
              </div>
              <div class="vx-col my-2 md:my-0 md:w-1/3 w-full">
                <vs-textarea v-validate="'required'" class="w-full mb-0" name="descr_en" rows="4" placeholder="English" v-model="combo.description_en"></vs-textarea>
                <span class="text-danger text-sm" v-show="errors.has('descr_en')">{{ errors.first('descr_en') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('categories')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <v-select multiple :options="categories" :label="`name_${$i18n.locale}`" name="categories" :placeholder="$t('select') + ' ' + $t('categories')" v-model="combo.categories" />
            <span class="text-danger text-sm" v-show="errors.has('categories')">{{ errors.first('categories') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('brand')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <v-select :options="brands" :label="`name_${$i18n.locale}`" name="brand" :placeholder="$t('select') + ' ' + $t('brand')" v-model="combo.brandId" :reduce="brand => brand.id" />
            <span class="text-danger text-sm" v-show="errors.has('brand')">{{ errors.first('brand') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.stockQt')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="{ required: true, min_value: combo.canPublished ? 1 : 0 }" name="stockQt" class="w-full" type="number" v-model="combo.stock_quantity" />
            <span class="text-danger text-sm" v-show="errors.has('stockQt')">{{ errors.first('stockQt') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.minStockQt')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="{ required: true, min_value: combo.canPublished ? 1 : 0 }" name="stockMinQt" class="w-full" type="number" v-model="combo.stock_min" />
            <span class="text-danger text-sm" v-show="errors.has('stockMinQt')">{{ errors.first('stockMinQt') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.price')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="'required_if:canPublished,true'" name="price" type="number" class="w-full" v-model="combo.given_price" />
            <span class="text-danger text-sm" v-show="errors.has('price')">{{ errors.first('price') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('discount.selectStartDate')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <flat-pickr v-validate="'required'" v-model="combo.from" :config="configdateDatePicker" class="w-full inputx" name="from" :placeholder="$t('discount.selectStartDate')" />
            <span class="text-danger text-sm" v-show="errors.has('from')">{{ errors.first('from') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('discount.selectEndDate')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <flat-pickr v-validate="'required'" v-model="combo.to" :config="configdateTimePicker" class="w-full inputx" name="to" :placeholder="$t('discount.selectEndDate')" />
            <span class="text-danger text-sm" v-show="errors.has('to')">{{ errors.first('to') }}</span>
          </div>
        </div>

        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{ $t('products') }}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <v-select multiple v-validate="'required_if:isActive,true'" :options="products" :label="`name_${$i18n.locale}`" name="products" :placeholder="$t('select')+' '+$t('products')" v-model="combo.collection_products" />
            <span class="text-danger text-sm" v-show="errors.has('products')">{{ errors.first('products') }}</span>
          </div>
          <div v-if="combo.collection_products.length" class="vx-col w-full py-3 border border-solid mx-4 mt-4 border-primary rounded-lg">
            <div v-for="product in combo.collection_products" :key="product.id" class="stripe p-3 flex">
              <span class="text-lg font-semibold">{{ product[`name_${$i18n.locale}`] }}</span>
              <vs-input v-validate="'required'" name="productQuantity" type="number" class="ml-auto" v-model="product.quantity" />
            </div>
            <span class="text-danger text-sm" v-show="errors.has('productQuantity')">{{ errors.first('productQuantity') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.type')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <v-select v-validate="'required'" class="w-full" :options="types" name="type" ref="type" :placeholder="$t('selectOne')" v-model="combo.type" :label="`name_${$i18n.locale}`" :reduce="opt => opt.value" />
            <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
          </div>
        </div>
        <template v-if="combo.type === 'both' || combo.type === 'express'">
          <div class="vx-row mb-6 mt-4">
            <div class="vx-col w-full sm:w-2/12 pt-2">
              <span class="text-lg font-semibold">{{ $t('fields.maxExpressOrderQt') }}:</span>
            </div>
            <div class="vx-col sm:w-10/12 w-full">
              <vs-input v-validate="'required_if:type,both,express'" name="expressMax" class="w-full" v-model="combo.express_max" />
              <span class="text-danger text-sm" v-show="errors.has('expressMax')">{{ errors.first('expressMax') }}</span>
            </div>
          </div>
        </template>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{ $t('fields.active') }}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <ul class="vx-row">
              <li class="vx-col my-2 ml-2 mr-4 flex">
                <vs-switch :disabled="!combo.isActive && !combo.images.length" name="isActive" ref="isActive" v-model="combo.isActive" />
                <label class="ml-3">{{$t('isActive')}}?</label>
              </li>
              <li class="vx-col my-2 ml-2 mr-4 flex">
                <vs-switch :disabled="!combo.canPublished && !combo.images.length" v-validate="'required_if:isActive,true'" name="canPublished" ref="canPublished" v-model="combo.canPublished" />
                <label class="ml-3">{{$t('canBePublished')}}?</label>
              </li>
              <li class="vx-col my-2 ml-2 mr-4 flex">
                <vs-switch name="isHit" ref="isHit" v-model="combo.isHit" />
                <label class="ml-3">{{$t('fields.isHitProduct')}}</label>
              </li>
              <li class="vx-col my-2 ml-2 mr-4 flex">
                <vs-switch name="isNew" ref="isNew" v-model="combo.isNew" />
                <label class="ml-3">{{$t('fields.isNewProduct')}}</label>
              </li>
            </ul>
            <span v-if="!combo.canPublished && !combo.images.length" class="text-danger text-sm">({{ $t('imageNotAdded') }})</span>
            <span class="block text-danger text-sm" v-show="errors.has('canPublished')">{{ errors.first('canPublished') }}</span>
          </div>
        </div>
        <vs-row>
          <vs-button type="filled" @click.prevent="submitForm" class="ml-auto">{{ $t('submit') }}</vs-button>
          <vs-button type="border" color="warning" @click.prevent="cancelForm" class="ml-4 mr-auto">{{ $t('cancel') }}</vs-button>
        </vs-row>
      </form>
    </vx-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
export default {
  components: {
    vSelect,
    flatPickr: () => import('vue-flatpickr-component')
  },
  data () {
    return {
      combo: {
        code: '',
        bar_code: '',
        name_tm: '',
        name_ru: '',
        name_en: '',
        description_tm: '',
        description_ru: '',
        description_en: '',
        given_price: null,
        type: 'market',
        express_max: null,
        stock_quantity: null,
        stock_min: null,
        isNew: false, //need
        isHit: false, //need
        isActive: false,
        from: null,
        to: null,
        brandId: null,
        categories: [],
        collection_products: [] //products
      },
      types: [
        {value: 'both', name_en: 'Both', name_tm: 'Ikisi hem', name_ru: 'Оба'},
        {value: 'market', name_en: 'Market', name_tm: 'Market', name_ru: 'Маркет'},
        {value: 'express', name_en: 'Express', name_tm: 'Ekspress', name_ru: 'Экспресс'}
      ],
      configdateTimePicker: {
        enableTime: true,
        time_24hr: true,
        locale: Russian
      },
      configdateDatePicker: {
        enableTime: true,
        time_24hr: true,
        locale: Russian
      },
      images: [],
      products: [],
      brands: [],
      categories: [],
      isLoading: true
    }
  },
  async created () {
    await this.fetchData()
    this.fetchProducts()
    this.fetchBrands()
    this.fetchCategories()
  },
  methods: {
    async fetchData () {
      await this.$http.get(`/collections/${this.$route.params.id}`).then(response => {
        this.combo =  {...response.data, to: response.data.to ? this.parseDate(response.data.to) : null, from: response.data.from ? this.parseDate(response.data.from) : null }
        this.combo.collection_products = this.combo.collection_products.map(el => { return {...el, quantity: el.Collectionproducts.quantity} })
        this.isLoading = false
      }).catch(() => { this.isLoading = false })
    },
    async fetchProducts () {
      await this.$http.get('/products', { params: { isActive: true } }).then(response => {
        if (response.status === 200) { this.products = response.data.data }
      })
    },
    async fetchBrands () {
      await this.$http.get('/brands', { params: { isActive: true } }).then(response => {
        if (response.status === 200) { this.brands = response.data.data }
      })
    },
    async fetchCategories () {
      await this.$http.get('/categories', { params: { isActive: true } }).then(response => {
        if (response.status === 200) { this.categories = response.data.data }
      })
    },
    parseDate (date) {
      // let newDate = date.split(',')
      // newDate = [...newDate[0].split('.').reverse(), ...newDate[1].split(':')]
      // newDate = newDate.map(el => Number(el))
      // newDate[1] = newDate[1] - 1
      return new Date(date)
    },
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/collections/edit/${this.combo.uuid}`, {
            ...this.combo,
            products: this.combo.collection_products.map(prod => { return {id: prod.id, quantity: Number(prod.quantity)} }),
            categories: this.combo.categories.map(cat => cat.id),
            images: null,
            collection_products: [],
            stock_quantity: Number(this.combo.stock_quantity)
          }).then(response => {
            if (response.status === 200) {
              this.notify('success', this.$t('notify.success'), `${this.$t('comboProduct')} ${this.$t('notify.sUpdated')}`)
              this.$router.push({ name: 'comboShow', params: {id: this.combo.uuid} })
            }
          }).catch(err => { this.notify('error', this.$t('notify.error'), err.response.data.message) })
        } else {
          this.notify('error', this.$t('notify.error'), this.$t('notify.formInvalid'))
        }
      })
    },
    cancelForm () { this.$router.back() },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'success' ? 'primary' : 'danger'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#combo-create-page {
  .stripe {
    background: #f6f6f6;
    &:nth-child(even) {
      background: transparent;
    }
  }
}
.theme-dark {
  #combo-create-page {
    .stripe {
      background: #181f43;
      &:nth-child(even) {
        background: transparent;
      }
    }
  }
}
</style>